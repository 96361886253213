<template>
  <b-row class="match-height">
    <b-col
      lg="8"
      class="space_"
    >
      <EditMeta />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditMeta from '@/components/meta/EditMeta.vue'

export default {
  components: {
    BRow,
    BCol,
    EditMeta,
  },
}
</script>

<style scoped>
.space_ {
  margin: 0 auto;
}
</style>
