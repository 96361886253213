import firebase from 'firebase/app'
import store from '@/store'

export default class ResetPassword {
  constructor() {
    this.response = ''
  }

  async sendMail() {
    const mail = store.getters['userBasic/mail']
    // const actionCodeSettings = {
    //   // パスワード再設定後のリダイレクト URL
    //   url: 'https://skillism.com/jplogin',
    //   handleCodeInApp: false,
    // }

    await firebase.auth()
      .sendPasswordResetEmail(mail)
      .then(() => {
        this.response = { status: 'success' }
      })
      .catch(error => {
        this.response = { status: 'error', msg: error }
      })

    return this.response
  }
}
