import firebase from 'firebase/app'

export default function GetSingleByDocID() {
  return {
    async getSingleData(payload) {
      const { collection, docID } = payload
      let dataDic = {}
      try {
        await firebase.firestore().collection(collection).doc(docID)
          .get()
          .then(doc => {
            const data = doc.data()
            if (data) { dataDic = data }
          })

        const response = {
          status: 'success',
          data: dataDic,
        }
        return response
      } catch (error) {
        const response = {
          status: 'error',
          msg: error,
        }
        return response
      }
    },
  }
}
