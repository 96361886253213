<template>
  <div>
    <h2>アカウント設定</h2>
    <div class="mb-2">
      アカウントの情報を設定しましょう。これはskillismをご利用いただくために必要な情報です。
    </div>
    <b-tabs align="left">
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" />
          <span class="iconSmartPhone">アカウント</span>
        </template>
        <b-card
          title=""
        >
          <b-form>
            <b-row
              v-for="(item, index) in menulist"
              :key="index"
            >
              <InputParts
                v-if="useValueDicList.indexOf(item.type) !== -1"
                :label="item.label"
                :type="item.type"
                :choice="typeof item.choice !== 'undefined'? item.choice: []"
                :value-dic="inputForm[item.key]"
                :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
                :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
                :explain="typeof item.explain !== 'undefined'? item.explain: []"
                :select="item.type ==='checkbox' ? choice: []"
                @inputData="inputForm[item.key] = $event"
              />
              <InputParts
                v-else-if="useValueListList.indexOf(item.type) !== -1"
                :label="item.label"
                :type="item.type"
                :choice="typeof item.choice !== 'undefined'? item.choice: []"
                :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
                :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
                :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
                :explain="typeof item.explain !== 'undefined'? item.explain: []"
                :select="item.type === 'checkboxplain' ? item.select: []"
                @inputData="inputForm[item.key] = $event"
              />
              <InputParts
                v-else
                :label="item.label"
                :type="item.type"
                :choice="typeof item.choice !== 'undefined'? item.choice: []"
                :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
                :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
                :explain="typeof item.explain !== 'undefined'? item.explain: []"
                :select="item.type ==='checkbox' ? choice: []"
                @inputData="inputForm[item.key] = $event"
              />
            </b-row>
            <b-row>
              <b-col lg="4">
                直近のログイン履歴
              </b-col>
              <b-col>
                {{ showHistory(inputForm['loginHistory']) }}
              </b-col>
            </b-row>
            <div v-if="errorList.length > 0">
              <b-alert
                variant="danger"
                show
                class="alertSpace"
              >
                <div class="alert-body">
                  <div class="">
                    {{ errorMSG }}
                  </div>
                  <div
                    v-for="(item, index) in errorList"
                    :key="index"
                    class=""
                  >
                    ・<b>{{ item }}</b>
                  </div>
                </div>
              </b-alert>
            </div>
            <b-row class="mt-2">
              <!-- submit and reset -->
              <b-col offset-md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="confirm"
                >
                  登録する
                  <b-spinner
                    v-if="status === 2"
                    small
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="KeyIcon" />
          <span class="iconSmartPhone">パスワード</span>
        </template>
        <b-card>
          <div class="">
            パスワードを変更する場合は登録されているメールアドレスに再設定メールを送ります。
          </div>
          <div class="mb-2">
            セキュリティ上の理由から、このページにパスワードは表示されません。
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            @click="sendMail"
          >
            再設定メールを送信する
            <b-spinner
              v-if="statusMail === 2"
              small
            />
          </b-button>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert, BTabs, BTab,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputParts from '@/components/molecules/input/InputParts.vue'
import menulist from '@/components/conf/meta/ユーザメタ情報.json'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
import UpdateUserData from '@/firestore/data/UpdateUserData'
import StoreUpdateWithoutFirestore from '@/firestore/store/StoreUpdateWithoutFirestore'
import SigleGetByDocID from '@/firestore/data/SigleGetByDocID'
import ResetPassword from '@/firestore/authentication/ResetPassword'
import dateformate from '@/components/js/filter/formatDateMinutesUniversal'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      statusMail: 1,
      isShow: false,
      menulist,
      choice,
      selected: [],
      useValueDicList: ['checkbox', 'selectSingle', 'cancelPolicy', 'radio'],
      useValueListList: ['checkboxplain', 'qualificationInput', 'serviceCategory', 'languageLevelSelect'],
      errorMSG: '',
      errorList: [],
    }
  },
  computed: {
    ...mapGetters('userBasic', ['userType', 'teacherID', 'uid', 'mail']),
  },
  async mounted() {
    this.fetch()
  },
  methods: {
    isInvalid() {
      this.errorList = []
      const datakeys = Object.keys(this.inputForm)
      this.menulist.forEach(item => {
        if (item.required && datakeys.indexOf(item.key) === -1) {
          // window.console.log('invalid', item.label)
          this.errorList.push(item.label)
        }
        if (item.required && typeof this.inputForm[item.key] === 'object') {
          if (Object.keys(this.inputForm[item.key]).length === 0) this.errorList.push(item.label)
        }
      })
      if (this.errorList.length > 0) {
        this.errorMSG = '下記の項目を入力してください。'
        return true
      }
      return false
    },
    async confirm() {
      this.status = 2
      const isInvalid = this.isInvalid()
      if (isInvalid) {
        this.status = 1
        return
      }
      const data = {
        collection: 'user',
        userType: this.userType,
        data: this.inputForm,
      }
      const updateUserData = new UpdateUserData()
      const response = await updateUserData.update(data)
      if (response.status === 'success') {
        this.success()
        this.fetch()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async fetch() {
      const data = {
        collection: 'user',
        docID: this.uid,
      }
      const sigleGetByDocID = new SigleGetByDocID()
      const response = await sigleGetByDocID.getSingleData(data)
      if (response.status === 'success') {
        this.inputForm = response.data
        const storeUpdateWithoutFirestore = new StoreUpdateWithoutFirestore(response.data)
        storeUpdateWithoutFirestore.update()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.status = 1
      })
    },
    cancel() {
      this.$router.push({ name: 'teacher-profile' })
    },
    async sendMail() {
      this.statusMail = 2
      const resetPassword = new ResetPassword()
      const res = await resetPassword.sendMail()
      if (res.status === 'success') {
        this.makeToast()
      } else {
        window.console.log('error', res.msg)
      }
      this.statusMail = 1
    },
    makeToast() {
      this.$bvToast.toast(`下記のメールアドレスにメールを送信しました。\n${this.mail}`, {
        title: '再設定メールを送信しました。',
        variant: 'primary',
        solid: true,
      })
    },
    showHistory(val) {
      if (val) {
        const len = val.length
        const history = val[len - 1]
        return dateformate(history.loginDate.toDate())
      }
      return ''
    },
  },
}
</script>
